import JSEncrypt from 'jsencrypt';
import { getPublicKey } from "@/service/cryptography";

/**
 * Custom composable for encryption functionality.
 * Fetches the public key and provides an encrypt method.
 */
export const useCryptography = () => {
  /**
   * Encrypts the given data using RSA encryption and the public key.
   * @param {Object} data - The data to encrypt.
   * @returns {Promise<string>} - The encrypted string.
   */
  const encrypt = async (data) => {
    try {
      if (!data) {
        throw new Error("Data to encrypt cannot be null or undefined.");
      }

      const publicKey = await getPublicKey(); // Fetch the public key from the server
      if (!publicKey || typeof publicKey !== "string") {
        throw new Error("Public key is invalid or not available after fetching.");
      }

      // Initialize JSEncrypt and set the public key
      const jsEncrypt = new JSEncrypt();
      jsEncrypt.setPublicKey(publicKey);

      // Encrypt the data
      const encryptedMessage  = jsEncrypt.encrypt(data);
      if (!encryptedMessage ) {
        throw new Error("Encryption failed.");
      }

      return encryptedMessage ;
    } catch (error) {
      console.error("Encryption error:", error);
      throw new Error("Failed to encrypt data.");
    }
  };

  // Return the encrypt function for external use.
  return {
    encrypt,
  };
};
