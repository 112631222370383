import { HTTP } from "@/service/axios";

/**
 * Fetches the public key from the server.
 * @returns {Promise} A promise that resolves to the public key as a string.
 * @throws Will throw an error if the request fails or the response is invalid.
 */
export var getPublicKey = function () {
    return HTTP.get("cryptography/publickey")
        .then(function (response) {
            // Check if the response indicates success
            if (response && response.data && response.data.success) {
                return response.data.obj; // Return the public key
            }

            // Throw an error if the response indicates failure
            throw new Error(response && response.data && response.data.message || "Failed to fetch public key");
        })
        .catch(function (error) {
            // Handle errors gracefully and provide meaningful feedback
            var errorMessage = error.response && error.response.data && error.response.data.message || error.message || "An unexpected error occurred";
            throw new Error(errorMessage);
        });
};
